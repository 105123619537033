export default {
  API_URL: 'https://hcm-backend.accqrate.com/api'
  // API_URL: 'http://localhost:9020/api'
}
export const cardColors = [
  '#4472C4',
  '#941751',
  '#FF2F92',
  '#531B93',
  '#76D6FF',
  '#0096FF',
  '#5E5E5E',
  '#FF9300',
  '#009051',
  '#BF9000',
  '#C55A11',
  '#FFFF00'
]
